<template>
  <div class="micro-app-wrapper">
    <!--
      name(必传)：应用名称 mall
      url(必传)：应用地址，会被自动补全为http://localhost:9527/
      baseroute(可选)：基座应用分配给子应用的基础路由 `/mall` 

              :keep-alive="true"  否则tab在子应用间的切换会降低页面加载速度
              :shadowDOM="true"   样式隔离，否则需要子应用的样式布局和主应用完全一致
    -->
    <micro-app
      v-if="condition"
      :name="microName"
      :url="url"
      :baseroute="baseroute"
      :inline="isInline()"
      :keep-alive="false"
      :shadowDOM="false"
      @created="handleCreated"
      @beforemount="handleBeforeMount"
      @mounted="handleMounted"
      @unmount="handleUnMount"
      @error="handleError" />
    <!-- 引起视图更新的dom -->
    <div v-if="divVif"></div>
  </div>
</template>

<script>
import config from '@/config'
export default {
  props: {
    microName: {
      type: String,
      default: 'mall'
    },
    componentUrl: {
      type: String,
      default: '/mall/home'
    },
    path: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      baseroute: '/',
      url: config[this.microName] + this.microName,
      condition: false,
      divVif: false
    }
  },
  methods: {
    handleDataChange(data) {
      this.println(this.microName + '子应用dispatch上来的数据', data)
    },
    handleCreated() {
      this.println(this.microName + '子应用已创建 for page:' + this.componentUrl)
    },
    handleBeforeMount() {
      this.println(this.microName + '子应用' + this.$route.name + ':before Mount')
    },
    handleMounted() {
      this.println(this.microName + '子应用' + this.componentUrl + ':Mount')
    },
    handleUnMount() {
      this.println(this.microName + '子应用' + this.componentUrl + ':' + this.$route.name + ':unMount 卸载完成了')
    },
    handleError() {
      this.println(this.microName + '子应用' + this.componentUrl + ':error 加载失败了')
    },
    isInline() {
      return !(process.env.NODE_ENV === "production")
    },
    // 输出带标志
    println(...args) {
      // 生产环境不打印
      if (process.env.NODE_ENV === 'production') {
        return
      }
      console.log(`[****MICRO-APP.vue****] `, ...args)
    },
    // 重新创建MicroApp实例
    rebuildMicro() {
      this.divVif = true
      this.condition = false
      this.$nextTick(() => {
        this.println('######################重新创建MicroApp实例######################')
        this.condition = true
      })
    }
  },
  mounted() {
    console.log('微前端页面挂载...')
    this.rebuildMicro()
  },
}
</script>
