import microApp from '@micro-zoe/micro-app'

// 开发环境地址
const config = {
  mall: 'http://localhost:9527/',
  wxmp: 'http://localhost:9528/',
  demo: 'http://localhost:8081/'
}

// 线上环境地址
if (process.env.NODE_ENV === 'production') {
  // 基座应用和子应用部署在同一个域名下，这里使用location.origin进行补全
  Object.keys(config).forEach(key => {
    config[key] = window.location.origin + '/'
  })
}

// ************************ 预加载 ***************************
function prefetch() {
  // apps
  const apps = []

  // push
  Object.keys(config).forEach(appName => {
    let appUrl = config[appName]
    apps.push({ name: appName, url: appUrl })
  })

  // fetch
  microApp.preFetch(apps)
}

// 生产环境执行
// if (process.env.NODE_ENV === 'production') {
//   prefetch()
// }

export default config
